import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _345548f5 = () => interopDefault(import('../src/pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _4cfb6f64 = () => interopDefault(import('../src/pages/delivery-enquete/index.vue' /* webpackChunkName: "pages/delivery-enquete/index" */))
const _6b7c69de = () => interopDefault(import('../src/pages/inflow/index.vue' /* webpackChunkName: "pages/inflow/index" */))
const _1fe7fa1d = () => interopDefault(import('../src/pages/integrate-user-error.vue' /* webpackChunkName: "pages/integrate-user-error" */))
const _05fb780c = () => interopDefault(import('../src/pages/line-account-renewal.vue' /* webpackChunkName: "pages/line-account-renewal" */))
const _094f3530 = () => interopDefault(import('../src/pages/location.vue' /* webpackChunkName: "pages/location" */))
const _0a5daf79 = () => interopDefault(import('../src/pages/mypage/index.vue' /* webpackChunkName: "pages/mypage/index" */))
const _5e64f410 = () => interopDefault(import('../src/pages/not-authorized.vue' /* webpackChunkName: "pages/not-authorized" */))
const _7a4d9a64 = () => interopDefault(import('../src/pages/not-pit-reservations.vue' /* webpackChunkName: "pages/not-pit-reservations" */))
const _bd2438ce = () => interopDefault(import('../src/pages/out-of-area.vue' /* webpackChunkName: "pages/out-of-area" */))
const _454e8e9c = () => interopDefault(import('../src/pages/pit-reservation/index.vue' /* webpackChunkName: "pages/pit-reservation/index" */))
const _4216a125 = () => interopDefault(import('../src/pages/quotations/index.vue' /* webpackChunkName: "pages/quotations/index" */))
const _0155d860 = () => interopDefault(import('../src/pages/receipt/index.vue' /* webpackChunkName: "pages/receipt/index" */))
const _0b06ce1c = () => interopDefault(import('../src/pages/terms.vue' /* webpackChunkName: "pages/terms" */))
const _5b6ceac4 = () => interopDefault(import('../src/pages/tire-guarantee.vue' /* webpackChunkName: "pages/tire-guarantee" */))
const _8f747232 = () => interopDefault(import('../src/pages/tire-repair.vue' /* webpackChunkName: "pages/tire-repair" */))
const _10cb66e1 = () => interopDefault(import('../src/pages/under-construction.vue' /* webpackChunkName: "pages/under-construction" */))
const _19a656e5 = () => interopDefault(import('../src/pages/unfollow-account.vue' /* webpackChunkName: "pages/unfollow-account" */))
const _d23a0226 = () => interopDefault(import('../src/pages/delivery-enquete/complete.vue' /* webpackChunkName: "pages/delivery-enquete/complete" */))
const _650585d7 = () => interopDefault(import('../src/pages/delivery-enquete/google-review-measurement.vue' /* webpackChunkName: "pages/delivery-enquete/google-review-measurement" */))
const _56133e3b = () => interopDefault(import('../src/pages/delivery-enquete/google-reviews.vue' /* webpackChunkName: "pages/delivery-enquete/google-reviews" */))
const _c9c3be9a = () => interopDefault(import('../src/pages/mypage/edit.vue' /* webpackChunkName: "pages/mypage/edit" */))
const _82590b08 = () => interopDefault(import('../src/pages/mypage/purchases/index.vue' /* webpackChunkName: "pages/mypage/purchases/index" */))
const _156e1b5a = () => interopDefault(import('../src/pages/mypage/quotations/index.vue' /* webpackChunkName: "pages/mypage/quotations/index" */))
const _3693361a = () => interopDefault(import('../src/pages/mypage/vehicles/index.vue' /* webpackChunkName: "pages/mypage/vehicles/index" */))
const _2e640409 = () => interopDefault(import('../src/pages/pit-reservation/complete.vue' /* webpackChunkName: "pages/pit-reservation/complete" */))
const _251544c0 = () => interopDefault(import('../src/pages/pit-reservation/confirm.vue' /* webpackChunkName: "pages/pit-reservation/confirm" */))
const _3deb871a = () => interopDefault(import('../src/pages/pit-reservation/register.vue' /* webpackChunkName: "pages/pit-reservation/register" */))
const _94b5e044 = () => interopDefault(import('../src/pages/pit-reservation/situation.vue' /* webpackChunkName: "pages/pit-reservation/situation" */))
const _7253e941 = () => interopDefault(import('../src/pages/pit-reservation/store.vue' /* webpackChunkName: "pages/pit-reservation/store" */))
const _550709e1 = () => interopDefault(import('../src/pages/pit-reservation/work.vue' /* webpackChunkName: "pages/pit-reservation/work" */))
const _c39d3a94 = () => interopDefault(import('../src/pages/quotations/complete.vue' /* webpackChunkName: "pages/quotations/complete" */))
const _e6a52056 = () => interopDefault(import('../src/pages/quotations/details.vue' /* webpackChunkName: "pages/quotations/details" */))
const _24536c2b = () => interopDefault(import('../src/pages/receipt/complete.vue' /* webpackChunkName: "pages/receipt/complete" */))
const _fc712d84 = () => interopDefault(import('../src/pages/receipt/confirm.vue' /* webpackChunkName: "pages/receipt/confirm" */))
const _8cb35832 = () => interopDefault(import('../src/pages/receipt/enquete.vue' /* webpackChunkName: "pages/receipt/enquete" */))
const _cd416654 = () => interopDefault(import('../src/pages/receipt/qr/index.vue' /* webpackChunkName: "pages/receipt/qr/index" */))
const _520cb6d6 = () => interopDefault(import('../src/pages/receipt/register.vue' /* webpackChunkName: "pages/receipt/register" */))
const _d233cace = () => interopDefault(import('../src/pages/vehicles/registration/index.vue' /* webpackChunkName: "pages/vehicles/registration/index" */))
const _49fd7192 = () => interopDefault(import('../src/pages/mypage/pit-reservation/complete.vue' /* webpackChunkName: "pages/mypage/pit-reservation/complete" */))
const _f0a8069c = () => interopDefault(import('../src/pages/mypage/pit-reservation/confirm.vue' /* webpackChunkName: "pages/mypage/pit-reservation/confirm" */))
const _c69f9b20 = () => interopDefault(import('../src/pages/mypage/pit-reservation/situation.vue' /* webpackChunkName: "pages/mypage/pit-reservation/situation" */))
const _187bad7c = () => interopDefault(import('../src/pages/receipt/qr/read.vue' /* webpackChunkName: "pages/receipt/qr/read" */))
const _1a3804c2 = () => interopDefault(import('../src/pages/vehicles/registration/complete.vue' /* webpackChunkName: "pages/vehicles/registration/complete" */))
const _7931e1c7 = () => interopDefault(import('../src/pages/vehicles/registration/confirm.vue' /* webpackChunkName: "pages/vehicles/registration/confirm" */))
const _7709532c = () => interopDefault(import('../src/pages/vehicles/registration/qr.vue' /* webpackChunkName: "pages/vehicles/registration/qr" */))
const _24911772 = () => interopDefault(import('../src/pages/vehicles/registration/select-type.vue' /* webpackChunkName: "pages/vehicles/registration/select-type" */))
const _61090fe7 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _253637ac = () => interopDefault(import('../src/pages/mypage/pit-reservation/_id.vue' /* webpackChunkName: "pages/mypage/pit-reservation/_id" */))
const _36942838 = () => interopDefault(import('../src/pages/mypage/purchases/_id.vue' /* webpackChunkName: "pages/mypage/purchases/_id" */))
const _1df5250a = () => interopDefault(import('../src/pages/mypage/quotations/_id.vue' /* webpackChunkName: "pages/mypage/quotations/_id" */))
const _146f081b = () => interopDefault(import('../src/pages/mypage/vehicles/_id.vue' /* webpackChunkName: "pages/mypage/vehicles/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/contact/",
    component: _345548f5,
    pathToRegexpOptions: {"strict":true},
    name: "contact"
  }, {
    path: "/delivery-enquete/",
    component: _4cfb6f64,
    pathToRegexpOptions: {"strict":true},
    name: "delivery-enquete"
  }, {
    path: "/inflow/",
    component: _6b7c69de,
    pathToRegexpOptions: {"strict":true},
    name: "inflow"
  }, {
    path: "/integrate-user-error/",
    component: _1fe7fa1d,
    pathToRegexpOptions: {"strict":true},
    name: "integrate-user-error"
  }, {
    path: "/line-account-renewal/",
    component: _05fb780c,
    pathToRegexpOptions: {"strict":true},
    name: "line-account-renewal"
  }, {
    path: "/location/",
    component: _094f3530,
    pathToRegexpOptions: {"strict":true},
    name: "location"
  }, {
    path: "/mypage/",
    component: _0a5daf79,
    pathToRegexpOptions: {"strict":true},
    name: "mypage"
  }, {
    path: "/not-authorized/",
    component: _5e64f410,
    pathToRegexpOptions: {"strict":true},
    name: "not-authorized"
  }, {
    path: "/not-pit-reservations/",
    component: _7a4d9a64,
    pathToRegexpOptions: {"strict":true},
    name: "not-pit-reservations"
  }, {
    path: "/out-of-area/",
    component: _bd2438ce,
    pathToRegexpOptions: {"strict":true},
    name: "out-of-area"
  }, {
    path: "/pit-reservation/",
    component: _454e8e9c,
    pathToRegexpOptions: {"strict":true},
    name: "pit-reservation"
  }, {
    path: "/quotations/",
    component: _4216a125,
    pathToRegexpOptions: {"strict":true},
    name: "quotations"
  }, {
    path: "/receipt/",
    component: _0155d860,
    pathToRegexpOptions: {"strict":true},
    name: "receipt"
  }, {
    path: "/terms/",
    component: _0b06ce1c,
    pathToRegexpOptions: {"strict":true},
    name: "terms"
  }, {
    path: "/tire-guarantee/",
    component: _5b6ceac4,
    pathToRegexpOptions: {"strict":true},
    name: "tire-guarantee"
  }, {
    path: "/tire-repair/",
    component: _8f747232,
    pathToRegexpOptions: {"strict":true},
    name: "tire-repair"
  }, {
    path: "/under-construction/",
    component: _10cb66e1,
    pathToRegexpOptions: {"strict":true},
    name: "under-construction"
  }, {
    path: "/unfollow-account/",
    component: _19a656e5,
    pathToRegexpOptions: {"strict":true},
    name: "unfollow-account"
  }, {
    path: "/delivery-enquete/complete/",
    component: _d23a0226,
    pathToRegexpOptions: {"strict":true},
    name: "delivery-enquete-complete"
  }, {
    path: "/delivery-enquete/google-review-measurement/",
    component: _650585d7,
    pathToRegexpOptions: {"strict":true},
    name: "delivery-enquete-google-review-measurement"
  }, {
    path: "/delivery-enquete/google-reviews/",
    component: _56133e3b,
    pathToRegexpOptions: {"strict":true},
    name: "delivery-enquete-google-reviews"
  }, {
    path: "/mypage/edit/",
    component: _c9c3be9a,
    pathToRegexpOptions: {"strict":true},
    name: "mypage-edit"
  }, {
    path: "/mypage/purchases/",
    component: _82590b08,
    pathToRegexpOptions: {"strict":true},
    name: "mypage-purchases"
  }, {
    path: "/mypage/quotations/",
    component: _156e1b5a,
    pathToRegexpOptions: {"strict":true},
    name: "mypage-quotations"
  }, {
    path: "/mypage/vehicles/",
    component: _3693361a,
    pathToRegexpOptions: {"strict":true},
    name: "mypage-vehicles"
  }, {
    path: "/pit-reservation/complete/",
    component: _2e640409,
    pathToRegexpOptions: {"strict":true},
    name: "pit-reservation-complete"
  }, {
    path: "/pit-reservation/confirm/",
    component: _251544c0,
    pathToRegexpOptions: {"strict":true},
    name: "pit-reservation-confirm"
  }, {
    path: "/pit-reservation/register/",
    component: _3deb871a,
    pathToRegexpOptions: {"strict":true},
    name: "pit-reservation-register"
  }, {
    path: "/pit-reservation/situation/",
    component: _94b5e044,
    pathToRegexpOptions: {"strict":true},
    name: "pit-reservation-situation"
  }, {
    path: "/pit-reservation/store/",
    component: _7253e941,
    pathToRegexpOptions: {"strict":true},
    name: "pit-reservation-store"
  }, {
    path: "/pit-reservation/work/",
    component: _550709e1,
    pathToRegexpOptions: {"strict":true},
    name: "pit-reservation-work"
  }, {
    path: "/quotations/complete/",
    component: _c39d3a94,
    pathToRegexpOptions: {"strict":true},
    name: "quotations-complete"
  }, {
    path: "/quotations/details/",
    component: _e6a52056,
    pathToRegexpOptions: {"strict":true},
    name: "quotations-details"
  }, {
    path: "/receipt/complete/",
    component: _24536c2b,
    pathToRegexpOptions: {"strict":true},
    name: "receipt-complete"
  }, {
    path: "/receipt/confirm/",
    component: _fc712d84,
    pathToRegexpOptions: {"strict":true},
    name: "receipt-confirm"
  }, {
    path: "/receipt/enquete/",
    component: _8cb35832,
    pathToRegexpOptions: {"strict":true},
    name: "receipt-enquete"
  }, {
    path: "/receipt/qr/",
    component: _cd416654,
    pathToRegexpOptions: {"strict":true},
    name: "receipt-qr"
  }, {
    path: "/receipt/register/",
    component: _520cb6d6,
    pathToRegexpOptions: {"strict":true},
    name: "receipt-register"
  }, {
    path: "/vehicles/registration/",
    component: _d233cace,
    pathToRegexpOptions: {"strict":true},
    name: "vehicles-registration"
  }, {
    path: "/mypage/pit-reservation/complete/",
    component: _49fd7192,
    pathToRegexpOptions: {"strict":true},
    name: "mypage-pit-reservation-complete"
  }, {
    path: "/mypage/pit-reservation/confirm/",
    component: _f0a8069c,
    pathToRegexpOptions: {"strict":true},
    name: "mypage-pit-reservation-confirm"
  }, {
    path: "/mypage/pit-reservation/situation/",
    component: _c69f9b20,
    pathToRegexpOptions: {"strict":true},
    name: "mypage-pit-reservation-situation"
  }, {
    path: "/receipt/qr/read/",
    component: _187bad7c,
    pathToRegexpOptions: {"strict":true},
    name: "receipt-qr-read"
  }, {
    path: "/vehicles/registration/complete/",
    component: _1a3804c2,
    pathToRegexpOptions: {"strict":true},
    name: "vehicles-registration-complete"
  }, {
    path: "/vehicles/registration/confirm/",
    component: _7931e1c7,
    pathToRegexpOptions: {"strict":true},
    name: "vehicles-registration-confirm"
  }, {
    path: "/vehicles/registration/qr/",
    component: _7709532c,
    pathToRegexpOptions: {"strict":true},
    name: "vehicles-registration-qr"
  }, {
    path: "/vehicles/registration/select-type/",
    component: _24911772,
    pathToRegexpOptions: {"strict":true},
    name: "vehicles-registration-select-type"
  }, {
    path: "/",
    component: _61090fe7,
    pathToRegexpOptions: {"strict":true},
    name: "index"
  }, {
    path: "/mypage/pit-reservation/:id?/",
    component: _253637ac,
    pathToRegexpOptions: {"strict":true},
    name: "mypage-pit-reservation-id"
  }, {
    path: "/mypage/purchases/:id/",
    component: _36942838,
    pathToRegexpOptions: {"strict":true},
    name: "mypage-purchases-id"
  }, {
    path: "/mypage/quotations/:id/",
    component: _1df5250a,
    pathToRegexpOptions: {"strict":true},
    name: "mypage-quotations-id"
  }, {
    path: "/mypage/vehicles/:id/",
    component: _146f081b,
    pathToRegexpOptions: {"strict":true},
    name: "mypage-vehicles-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
