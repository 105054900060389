import { actionTree, getterTree, mutationTree } from 'typed-vuex'
import { getClient } from '~/graphql/client'
import {
  StoreInfoAttributesFragment,
  StoresInput,
} from '~/graphql/generated/types'
import { logger } from '~/utils/logger'

const defaultState = () => {
  return {
    _shops: [] as StoreInfoAttributesFragment[],
  }
}

export const state = () => defaultState()

export const getters = getterTree(state, {
  shops: (state) => state._shops,
})

export const mutations = mutationTree(state, {
  setShops(state, val: StoreInfoAttributesFragment[]) {
    state._shops = val
  },
  clear(state) {
    Object.assign(state, defaultState())
  },
})

export const actions = actionTree(
  { state, getters, mutations },
  {
    async getShops(
      { commit },
      input: StoresInput
    ): Promise<StoreInfoAttributesFragment[]> {
      logger.info('店舗一覧を取得', { input })
      const shops = await getClient()
        .stores({ input })
        .then((data) => data.stores)

      commit('setShops', shops)

      return shops
    },
    async getShop(
      _,
      {
        shopId,
        ignoreEstimateFlg,
      }: { shopId: number; ignoreEstimateFlg?: number }
    ): Promise<StoreInfoAttributesFragment | undefined> {
      const shops = await this.app.$accessor.shop.getShops({
        ignoreEstimateFlg,
      })
      return shops.find((shop) => shop.shopId === shopId)
    },
  }
)

export type RootState = ReturnType<typeof state>
